import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Cityset from '../containers/cityset';

const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    typeof window !== 'undefined' && window.localStorage.getItem(localStorageKey)
    || ''
  );
 
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(localStorageKey, value);
    }
  }, [value]);
 
  return [value, setValue];
};

const App = () => {
  const [value, setValue] = useStateWithLocalStorage(
    'cityname'
  );
 
  const onChange = event => setValue(event.target.value);
 
  return (
    <Layout>
            <SEO
        title="都市の設定"
        description="都市の設定画面です"
      />

    <Cityset />

    </Layout>
  );
};

export default App;